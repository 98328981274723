html {
  width: 100%;
  height: 100%;
  min-height: 618px;
  font-size: 40px;
  /* overflow-x: hidden; */
}

input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (max-width: 4400px) {
  html {
    font-size: 36px;
  }
}

@media screen and (max-width: 4000px) {
  html {
    font-size: 32px;
  }
}

@media screen and (max-width: 3600px) {
  html {
    font-size: 28px;
  }
}

@media screen and (max-width: 3200px) {
  html {
    font-size: 26px;
  }
}

@media screen and (max-width: 2800px) {
  html {
    font-size: 22px;
  }
}

@media screen and (max-width: 2500px) {
  html {
    font-size: 20px;
  }
}

@media screen and (max-width: 2000px) {
  html {
    font-size: 16px;
  }
}

body {
  overflow-x: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  color: #001d2f;
  font-family:
    -apple-system,
    Microsoft YaHei,
    BlinkMacSystemFont,
    Inter,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-feature-settings: "liga";
  font-family: -apple-system, BlinkMacSystemFont, FS Kim, Georgia, "Serif";
  font-weight: 500 !important;
}

code {
  font-family: Consolas, Monaco, Andale Mono, monospace
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  background-color: #fff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #ccc;
}

h3,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#app {
  width: 100%;
  height: 100%;
}

/* 隐藏51.La 网站流量统计系统 */
a[title^="51.La"] {
  display: none !important;
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: "normal";
  font-weight: 400;
  /* webpackIgnore: true */
  src: url(/fonts/inter-regular.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: "normal";
  font-weight: 500;
  /* webpackIgnore: true */
  src: url(/fonts/inter-medium.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: "normal";
  font-weight: 600;
  /* webpackIgnore: true */
  src: url(/fonts/inter-semibold.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: "normal";
  font-weight: 700;
  /* webpackIgnore: true */
  src: url(/fonts/inter-bold.woff) format("woff")
}

@font-face {
  font-display: swap;
  font-family: FS Kim;
  font-style: normal;
  font-weight: 500;
  /* webpackIgnore: true */
  src: url(/fonts/fs-kim-text-w03-medium.woff) format("woff")
}